<template>
  <div id="app">
    <van-nav-bar title="历史报修" left-text="返回" left-arrow @click-left="onClickLeft" @click-right="addCm">
      <template #right>
        <van-icon name="plus" size="22" />
      </template>
    </van-nav-bar>
    <van-tabs v-model:active="active" :ellipsis="false" @click="clickTabs">
      <van-tab>
        <template #title>全部 ({{ totalApply }})</template>
      </van-tab>
      <van-tab>
        <template #title>待维修 ({{ toDoApply }})</template>
      </van-tab>
      <van-tab>
        <template #title>维修中 ({{ doingApply }})</template>
      </van-tab>
<!--      <van-tab>-->
<!--        <template #title>待确认 ({{ toDoneApply }})</template>-->
<!--      </van-tab>-->
      <van-tab>
        <template #title>维修完成 ({{ doneApply }})</template>
      </van-tab>
    </van-tabs>
    <van-search v-model="mobileSearch" placeholder="请输入搜索关键词" @search="onSearch" class="asset-search" />
    <div class="common-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore" offset="1" :immediate-check="false">
          <van-cell v-for="(item, index) in applyList" :key="index">
            <div class="clearfix" @click="goUrls(`/project/apply_detail/${item.id}`)">
              <div class="title">
                <div class="fl tit">{{ item.name }}<br>{{ item.maintain_code }}</div>
                <div class="fr status">
                  <div v-if="item.status === 0">
                    <div class="status0"><span></span>待维修</div>
                  </div>
                  <div v-if="[-1, 1, 2].includes(item.status)">
                    <div class="status1"><span></span>维修中</div>
                  </div>
                  <div v-if="[3, 4, 5].includes(item.status)">
                    <div class="status2"><span></span>维修完成</div>
                  </div>
<!--                  <div v-if="[4, 5].includes(item.status)">-->
<!--                    <div class="status2"><span></span>维修完成</div>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="detail">
                <div class="info">是否停产：{{ item.is_stop }}</div>
                <div class="info">维修检测：{{ item.tag }}</div>
                <div class="info">发现时间：{{ item.create_time }}</div>
                <div class="info">完成时间：{{ item.end_time }}</div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import API from '@/api/project/apply'
export default {
  inject: ['goUrl'],
  data() {
    return {
      active: 0,
      query: { page: 0, limit: 15 },
      mobileSearch: '',
      applyList: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishLoading: false,
      totalApply: 0,
      toDoApply: 0,
      doingApply: 0,
      toDoneApply: 0,
      doneApply: 0
    }
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      this.finishLoading = false
      this.query.page += 1
      if (this.refreshing) {
        this.applyList = []
        this.refreshing = false
      }
      return API.applyList(this.query).then(res=> {
        this.totalApply = res.data.m_total
        this.toDoApply = res.data.to_do
        this.doingApply = res.data.doing
        this.toDoneApply = res.data.to_done
        this.doneApply = res.data.done
        if (res.data.error_code === 888) {
          Toast('暂无申请')
          this.applyList = []
        } else {
          this.applyList.push(...res.data.data)
          this.loading = false

          // if (this.active === 1) {
          //   this.applyList.length >= this.toDoApply ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 2) {
          //   this.applyList.length >= this.doingApply ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 3) {
          //   this.applyList.length >= this.toDoneApply ? (this.finished = true) : (this.finished = false)
          // } else if (this.active === 4) {
          //   this.applyList.length >= this.doneApply ? (this.finished = true) : (this.finished = false)
          // } else {
          //   this.applyList.length >= this.totalApply ? (this.finished = true) : (this.finished = false)
          // }

          if (this.active === 1) {
            this.applyList.length >= this.toDoApply ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 2) {
            this.applyList.length >= this.doingApply ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 3) {
            console.log(this.applyList)
            this.applyList.length >= this.doneApply ? (this.finished = true) : (this.finished = false)
          } else {
            this.applyList.length >= this.totalApply ? (this.finished = true) : (this.finished = false)
          }
        }
      }).finally(() => {
        this.finishLoading = true
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onSearch() {
      this.query.mobileSearch = this.mobileSearch
      this.query.page = 0
      this.refreshing = true
      this.index()
    },
    onRefresh() {
      this.applyList = []
      this.query.page = 0
      this.finished = true
      this.index()
    },
    loadMore() {
      if (this.finishLoading === true) {
        this.index()
      }
    },
    goUrls(url) {
      this.goUrl(url)
    },
    addCm() {
      this.goUrls('/project/apply_add')
    },
    clickTabs(name) {
      this.applyList = []
      this.query.page = 0
      this.finished = true
      this.query.status = name
      console.log(this.query.status)
      this.index()
    }
  }
}
</script>
<style>
.asset-search{
  background-color: #ebeef5;
}
.status0{
  color: blue;
}
.status0 span{
  background-color: blue !important;
}
.status1{
  color: orange;
}
.status1 span{
  background-color: orange !important;
}
.status2{
  color: green;
}
.status2 span{
  background-color: green !important;
}
.clearfix{
  clear: both;
}
.clearfix:after {
  content:"";
  display: block;
  clear:both;
}
</style>

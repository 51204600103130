import request from "@/utils/request"

export default {
    applyList(params) {
        return request({
            url: '/v1/maintainlist',
            method: 'get',
            params
        })
    },
    getOne(params) {
        return request({
            url: '/v1/cm',
            method: 'get',
            params
        })
    },
    update(data) {
        return request({
            url: '/v1/maintainAdd',
            method: 'post',
            data
        })
    },
    create(params) {
        return request({
            url: '/v1/maintainAdd',
            method: 'get',
            params
        })
    },
    delete(id) {
        return request({
            url: `/v1/maintainDel/${id}`,
            method: 'get'
        })
    },
    statusCount(params) {
        return request({
            url: '/v1/maintainStatus',
            method: 'get',
            params
        })
    }
}
